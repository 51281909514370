import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import "react-slideshow-image/dist/styles.css";
import { initPage } from "../../api/Api";
import Loading from "../Loading";
import { Cell, Grid, Row } from "../FoundationGrid";
import CallToAction from "../CallToAction";
import RevealFade from "react-reveal/Fade";
import Icon from "../Icon";
import Section from "../Section";
import { Link } from "gatsby";

const identifier = "links";

function LinksPage({ locale }) {
  const [page, setPage] = useState({
    identifier: identifier,
    nav: "links",
    locale: locale,
    parts: { mainPageSections: [], mainPageSpecialSections: [], links: null },
    ctas: { home: null },
  });

  const init = () => {
    initPage(page, (page) => setPage({ ...page }));
  };

  useEffect(init, []);

  if (!page.data || !page.parts || !page.parts.links)
    return <Loading page={page} fullscreen />;

  const { links } = page.parts;

  return (
    <div className={`page main-page page-${page.identifier}`}>
      <MainLayout page={page}>
        <Section style={{ padding: "40px 0" }}>
          <h1 className="text-center" style={{ marginBottom: "40px" }}>
            {page.data.Title}
          </h1>
          <div className="__links-links">
            <Grid>
              <Row>
                <Cell sm={24} md={16} mdo={4}>
                  {links.map((link, index) => {
                    if (link.Type === "separator") return <hr key={index} />;
                    let classes = "__links-link";

                    if (link.Type === "featured")
                      classes = `${classes} primary button`;
                    if (link.Type === "default")
                      classes = `${classes} primary hollow button`;

                    return (
                      <React.Fragment key={index}>
                        {link.Url.substring(0, 1) === "/" ? (
                          <Link to={link.Url} className={classes}>
                            <LinkContent link={link} />
                          </Link>
                        ) : (
                          <a
                            href={link.Url}
                            target="_blank"
                            className={classes}
                          >
                            <LinkContent link={link} />
                          </a>
                        )}
                      </React.Fragment>
                    );
                  })}
                </Cell>
              </Row>
            </Grid>
          </div>
        </Section>
        <RevealFade bottom>
          <Grid>
            <Row>
              <Cell sm={24}>
                <CallToAction type="box" cta={page.ctas.home} />
              </Cell>
            </Row>
          </Grid>
        </RevealFade>
      </MainLayout>
    </div>
  );
}

const LinkContent = ({ link }) => {
  return (
    <>
      {link.Icon && <Icon icon={link.Icon} left />} {link.Label}
    </>
  );
};

export default LinksPage;
